<template>
    <div class="menubutton" @click="toggleMenu" :class="{'active':activemenu}">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
    </div>
</template>

<script>
    export default {
        name: "menubutton",

        data() {
            return {
            }
        },
        props: ['activemenu'],
        methods: {
            toggleMenu() {
                this.$emit('update:activemenu', !this.activemenu)
            }
        }
    }
</script>

<style lang="scss" type="text/scss" scoped>
    .menubutton {

        @include mobile {
            display: inline-block;
        }
        display: none;
        transform: scale(0.8);
        cursor: pointer;
        .bar1, .bar2, .bar3 {
            position: relative;

            width: 25px;
            height: 3px;
            background:#333;
            margin: 7px 10px;
            transition: 0.4s;
        }
        /* Rotate first bar */
        &.active {
            transform: scale(1.1);
            .bar1 {
            background: #333;

                transform: rotate(45deg) translate(2px,-1px) ;
                transform-origin: top left;
            }
            .bar2 {
                opacity: 0;
            }
            .bar3 {
                transform-origin: left;
                background: #333;
                transform: rotate(-45deg) translate(2px, -1px) ;
            }
        }

    }

</style>